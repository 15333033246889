import React from "react"

export default class CampaignRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.toggleBrandHidden = this.toggleBrandHidden.bind(this)
  }

  toggleBrandHidden() {
    fetch(this.props.campaign.url_toggle_brand_hidden, {
      method: 'POST',
      headers: {},
      body: {}
    }).then(response => {
      return response.json();
    }).then((_) => {
      window.location.reload();
    })
  }

  render () {
    let { campaign } = this.props;

    let toggleIcon = () => {
      if (campaign.brand_hidden) {
        return (
          <i className="fas fa-toggle-off clickable" onClick={this.toggleBrandHidden} style={{ color:"#00000050" }}></i>
        )
      } else {
        return (
          <i className="fas fa-toggle-on clickable" onClick={this.toggleBrandHidden}></i>
        )
      }
    }
    let dwells = campaign.dwells
    let dwellAvg = (campaign.dwell_seconds / dwells)
    let eligible = campaign.eligible_choices
    let claimed = campaign.pin_status_claimed
    let prefenceAvg = claimed / eligible

    return (
      <tr key={`campaign-${campaign.id}-product-${campaign.product.id}`} className={campaign.machine.hidden ? 'opacity25' : ''}>
        <td className="center-text" style={{ fontSize:"20px",padding:"0 8px" }}>
          {toggleIcon()}
        </td>
        <td>{campaign.id}</td>
        <td>{campaign.brand.name}</td>
        <td>{campaign.product.name}</td>
        <td>{campaign.machine.name}</td>
        <td className="right-text">{numberWithCommas(campaign.impressions)}</td>
        <td className="right-text">{numberWithCommas(dwells)}</td>
        <td className="right-text">{dwells > 0 ? Math.round(dwellAvg * 100) / 100 : 0}</td>
        <td className="bold right-text">{numberWithCommas(campaign.qr_scans)}</td>
        <td className="bold right-text">{numberWithCommas(campaign.pin_requests)}</td>
        <td className="bold right-text">{numberWithCommas(campaign.pin_shown)}</td>
        <td className="bold right-text">{numberWithCommas(campaign.pin_shown_unique)}</td>
        <td className="bold right-text">{numberWithCommas(campaign.pin_inputs)}</td>
        <td className="bold right-text">{numberWithCommas(campaign.pin_inputs_valid)}</td>
        <td className="bold right-text">{numberWithCommas(eligible)}</td>
        <td className="bold right-text">{numberWithCommas(claimed)}</td>
        <td className="right-text">{eligible > 0 ? Math.round(prefenceAvg * 100) : 0}%</td>
        <td className="bold right-text">{numberWithCommas(campaign.pin_status_dispense_error)}</td>
        <td className="bold right-text">{numberWithCommas(campaign.shop_click_text)}</td>
        <td className="bold right-text">{numberWithCommas(campaign.engagement_click_text)}</td>
        <td className="bold right-text">{numberWithCommas(campaign.rebate_sign_ups)}</td>
        <td className="bold right-text">{numberWithCommas(campaign.orders)}</td>
        <td className="bold right-text">{numberWithCommas(campaign.brand_orders)}</td>
      </tr>
    )
  }
}

function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
