import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import SurveyQuestionRow from "./SurveyQuestionRow";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idDirection: '',
      messageDirection: '',
      onDesktop: true,
      sortedSurveyQuestions: this.props.survey_questions,
    }

    this.sortById = this.sortById.bind(this)
    this.sortByMessage = this.sortByMessage.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  sortById() {
    let surveyQuestions = this.state.sortedSurveyQuestions
    let direction = '';

    if (this.state.idDirection == "DESC") {
      surveyQuestions = surveyQuestions.sort((a, b) => a.id > b.id ? -1 : 1);
      direction = "ASC"
    } else {
      surveyQuestions = surveyQuestions.sort((a, b) => a.id > b.id ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      messageDirection: '',
      idDirection: direction,
      sortedSurveyQuestions: surveyQuestions,
    })
  }

  sortByMessage() {
    let surveyQuestions = this.state.sortedSurveyQuestions
    let direction = '';

    if (this.state.messageDirection == "DESC") {
      surveyQuestions = surveyQuestions.sort((a, b) => a.message > b.message ? -1 : 1);
      direction = "ASC"
    } else {
      surveyQuestions = surveyQuestions.sort((a, b) => a.message > b.message ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      messageDirection: direction,
      idDirection: '',
      sortedSurveyQuestions: surveyQuestions,
    })
  }

  render () {
    let { showdrop_logo, url_new } = this.props;
    let { idDirection, messageDirection, onDesktop, sortedSurveyQuestions } = this.state;
    let pageName = 'survey_questions';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Survey Questions'}
              url={url_new}
            />

            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width:"80px" }}>
                    <caption style={{ padding:"0" }}>
                      <button type="button" className="btn btn-sm btn-success mlr-5" style={{ fontSize:"12px",padding:"3px 6px",marginLeft:"10px" }} onClick={(e) => { e.stopPropagation(); this.goToUrl(url_new) }}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </caption>
                  </th>
                  <th scope="col" style={{ width:"80px" }}>Active?</th>
                  <th scope="col" className="sortable" onClick={this.sortById} style={{ width:"50px" }}>
                    ID
                    <RenderArrow direction={idDirection} />
                  </th>
                  <th scope="col" className="sortable" onClick={this.sortByMessage}>
                    Message
                    <RenderArrow direction={messageDirection} />
                  </th>
                  <th scope="col">Description</th>
                  <th scope="col">Presentation</th>
                  <th scope="col">Options</th>
                </tr>
              </thead>
              <tbody>
                {sortedSurveyQuestions.map(surveyQuestion => {
                  return (
                    <SurveyQuestionRow
                      surveyQuestion={surveyQuestion}
                      machines={this.props.machines}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function RenderArrow(props) {
  let { direction } = props;

  if (direction) {
    if (direction == "DESC") {
      return (
        <i className="fas fa-caret-down"></i>
      )
    } else {
      return (
        <i className="fas fa-caret-up"></i>
      )
    }
  } else {
    return null;
  }
}
