import React from "react";
import Chart from "chart.js/auto";
import ProductRow from "./ProductRow";
import { Bar, ChartDataLabels } from "react-chartjs-2";

export default class CampaignRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedCampaign: {},
      campaignLoading: true,
      showContent: false
    };

    this.fetchCampaignData = this.fetchCampaignData.bind(this)
    this.toggleContent = this.toggleContent.bind(this)
  }

  componentDidMount() {
    this.fetchCampaignData()
  }

  fetchCampaignData() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.campaign.id,
    })
    formData.append('campaign', data)

    fetch(this.props.campaign.url_fetch_campaign_data, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          campaignLoading: false,
          loadedCampaign: data.campaign,
        })

        that.props.collectClickThroughs(data.campaign.click_throughs)
        that.props.collectRebateRedemptions(data.campaign.rebate_redemptions)
        that.props.collectRebateSignUps(data.campaign.rebate_sign_ups)
        that.props.collectImpressions(data.campaign.impressions)
        that.props.collectDwells(data.campaign.dwells)
        that.props.collectQrScans(data.campaign.qr_scans)
        that.props.collectSamplesClaimed(data.campaign.samples_claimed)
      }
    })
  }

  toggleContent() {
    let showContent = !this.state.showContent

    this.setState({
      showContent: showContent
    })
  }

  render() {
    let { totalClickThroughs, totalRebateRedemptions, totalRebateSignUps } = this.props;
    let { loadedCampaign, campaignLoading, showContent } = this.state;

    let engagementBarGraph = () => {
      if (JSON.stringify(loadedCampaign.engagement_data) === '{}') {
        return (
          <></>
        )
      } else {
        const data = {
          labels: loadedCampaign.engagement_data.labels,
          datasets: loadedCampaign.engagement_data.datasets,
        };
        const options = {
          barPercentage: 0.9,
          categoryPercentage: 0.75,
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: false,
              text: "Sampling Overview",
            }
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              grid: {
                display: true,
              },
              stacked: true,
            },
            y: {
              type: 'logarithmic',
              grid: {
                display: true,
              },
              ticks: {
                display: true,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        };

        return (
          <Bar data={data} options={options} />
        );
      }
    };
    let demographicBarGraph = (graph_data) => {
      if (JSON.stringify(graph_data) === '{}') {
        return (
          <></>
        )
      } else {
        const data = {
          labels: graph_data.labels,
          datasets: graph_data.datasets,
        };
        const options = {
          barPercentage: 1,
          categoryPercentage: 1,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
              max: 100
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              position: "bottom",
            },
            tooltip: {
              mode: 'index',
              callbacks: {
                label: function(context) {
                  var label = context.dataset.label || '';
                  if (context.parsed.x !== null) {
                    label += ' ' +context.parsed.x + '%';
                  }
                  return label;
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1,
        };

        return (
          <Bar data={data} options={options} height={"160px"} />
        );
      }
    };
    let showRebateCountForCampaign = (campaign) => {
      if (totalRebateSignUps > 0) {
        return (
          <>
            {
              campaign.enable_rebate ? (
                <td className="bold">{numberWithCommas(campaign.rebate_sign_ups)}</td>
              ) : (
                <td>N/A</td>
              )
            }
            {
              totalRebateRedemptions > 0 ? (
                campaign.enable_rebate ? (
                  <td className="bold">{numberWithCommas(campaign.rebate_redemptions)}</td>
                ) : (
                  <td>N/A</td>
                )
              ) : (
                null
              )
            }
          </>
        )
      } else {
        return null;
      }
    }
    let showClickThroughCountForCampaign = (campaign) => {
      if (totalClickThroughs > 0) {
        return (
          <td className="bold">{numberWithCommas(campaign.click_throughs)}</td>
        )
      } else {
        return null;
      }
    }
    const checkRecentlyClaimedCount = (count) => {
      if (count && count > 0) {
        return (
          <span style={{ color:"green",fontSize:"10px",paddingLeft:"2px" }}>
            (+{numberWithCommas(count)})
          </span>
        )
      }
    }
    let campaignOnSelectedMachine = campaignLoading ? null : ((loadedCampaign.machine.name == this.props.selectedMachine || this.props.selectedMachine == "All") ? loadedCampaign : null)

    return (
      <>
        {
          campaignLoading ? (
            <tr style={{ position:"relative",height:"30px" }}>
              <div style={{ position:"absolute",width:"100%",height:"100%",backgroundColor:"#00000020",color:"white",fontWeight:"bold",borderTop:"1px solid white",borderBottom:"1px solid white" }}>
                <i className="fas fa-circle-notch fa-spin"></i>
                <span style={{ paddingLeft:"4px" }}>Loading Campaign Data...</span>
              </div>
            </tr>
          ) : (
            campaignOnSelectedMachine ? (
              <tr onClick={campaignLoading ? null : this.toggleContent} key={`campaign-${campaignLoading ? "" : loadedCampaign.id}`} className={`hoverable ${campaignLoading ? "" : (loadedCampaign.machine.hidden ? "opacity25" : "")}`} style={{ position:"relative" }}>
                <td style={{ minWidth:"22px" }}>
                  {
                    showContent ? (
                      <span><i className="fas fa-caret-down"></i></span>
                    ) : (
                      <span><i className="fas fa-caret-right"></i></span>
                    )
                  }
                </td>
                <td>{loadedCampaign.date}</td>
                <td>{loadedCampaign.machine.name}</td>
                <td>{loadedCampaign.brand.name}</td>
                <td>{loadedCampaign.product.name}</td>
                <td></td>
                <td className="bold">{numberWithCommas(loadedCampaign.impressions)}</td>
                <td className="bold">{numberWithCommas(loadedCampaign.dwells)}</td>
                <td className="bold">{numberWithCommas(loadedCampaign.qr_scans)}</td>
                <td className="bold">
                  {numberWithCommas(loadedCampaign.samples_claimed)}
                  {checkRecentlyClaimedCount(loadedCampaign.samples_claimed_24hours)}
                </td>
                {
                  totalClickThroughs > 0 || totalRebateSignUps > 0 ? (
                    <td></td>
                  ) : (
                    null
                  )
                }
                {showClickThroughCountForCampaign(loadedCampaign)}
                {showRebateCountForCampaign(loadedCampaign)}
              </tr>
            ) : (
              null
            )
          )
        }

        {
          campaignLoading ? (
            null
          ) : (
            campaignOnSelectedMachine && showContent ? (
              <tr>
                <td style={{ padding:"30px 0" }} colSpan={`${totalRebateSignUps > 0 ? "13" : "11"}`}>
                  <div className="bg-white">
                    <div style={{ display:"flex" }}>
                      <div style={{ flex:"1",padding:"10px" }}>
                        <div style={{ padding:"0 0 10px",fontSize:"16px",fontWeight:"bold" }}>Summary of Customer Engagements</div>

                        <div className="h300">
                          {engagementBarGraph()}
                        </div>
                      </div>

                      <div style={{ flex:"1",padding:"10px" }}>
                        <div style={{ padding:"0 0 10px",fontSize:"16px",fontWeight:"bold" }}>Demographic Breakdown of "Samples Claimed"</div>

                        <div style={{ height:"160px" }}>
                          {
                            loadedCampaign.gender_data_exists ? (
                              demographicBarGraph(loadedCampaign.gender_data)
                            ) : (
                              <div style={{ height:"160px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                                <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>Gender Data Unavailable</div>
                              </div>
                            )
                          }
                        </div>

                        <div style={{ height:"160px" }}>
                          {
                            loadedCampaign.age_data_exists ? (
                              demographicBarGraph(loadedCampaign.age_data)
                            ) : (
                              <div style={{ height:"160px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                                <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>Age Data Unavailable</div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>

                    <div>
                      <ProductRow
                        product={loadedCampaign.product}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              null
            )
          )
        }
      </>
    )
  }
}
function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
