import React from "react";
import CampaignRow from "./CampaignRow";
import { Bar } from "react-chartjs-2";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ageDataExists: false,
      ageDataLoading: true,
      ageData: {},
      genderDataExists: false,
      genderDataLoading: true,
      genderData: {},
      selectedMachine: "All",
      selectedMachineId: 0,
      totalClickThroughs: 0,
      totalRebateRedemptions: 0,
      totalRebateSignUps: 0,
      totalImpressions: 0,
      totalDwells: 0,
      totalQrScans: 0,
      totalSamplesClaimed: 0,
    };

    this.collectClickThroughs = this.collectClickThroughs.bind(this)
    this.collectRebateRedemptions = this.collectRebateRedemptions.bind(this)
    this.collectRebateSignUps = this.collectRebateSignUps.bind(this)
    this.collectImpressions = this.collectImpressions.bind(this)
    this.collectDwells = this.collectDwells.bind(this)
    this.collectQrScans = this.collectQrScans.bind(this)
    this.collectSamplesClaimed = this.collectSamplesClaimed.bind(this)
    this.fetchAgeData = this.fetchAgeData.bind(this)
    this.fetchGenderData = this.fetchGenderData.bind(this)
    this.setSelectedMachine = this.setSelectedMachine.bind(this)
  }

  componentDidMount() {
    this.fetchAgeData()
    this.fetchGenderData()
  }

  collectClickThroughs(e) {
    let currentCount = this.state.totalClickThroughs

    this.setState({
      totalClickThroughs: currentCount + e
    })
  }

  collectRebateRedemptions(e) {
    let currentCount = this.state.totalRebateRedemptions

    this.setState({
      totalRebateRedemptions: currentCount + e
    })
  }

  collectRebateSignUps(e) {
    let currentCount = this.state.totalRebateSignUps

    this.setState({
      totalRebateSignUps: currentCount + e
    })
  }

  collectImpressions(e) {
    let currentCount = this.state.totalImpressions

    this.setState({
      totalImpressions: currentCount + e
    })
  }

  collectDwells(e) {
    let currentCount = this.state.totalDwells

    this.setState({
      totalDwells: currentCount + e
    })
  }

  collectQrScans(e) {
    let currentCount = this.state.totalQrScans

    this.setState({
      totalQrScans: currentCount + e
    })
  }

  collectSamplesClaimed(e) {
    let currentCount = this.state.totalSamplesClaimed

    this.setState({
      totalSamplesClaimed: currentCount + e
    })
  }

  fetchAgeData() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      machine_id: this.state.selectedMachineId,
    })
    formData.append('campaign', data)

    fetch(this.props.url_fetch_age_data, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          ageDataExists: data.age_data_exists,
          ageDataLoading: false,
          ageData: data.age_data,
        })
      }
    })
  }

  fetchGenderData() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      machine_id: this.state.selectedMachineId,
    })
    formData.append('campaign', data)

    fetch(this.props.url_fetch_gender_data, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          genderDataExists: data.gender_data_exists,
          genderDataLoading: false,
          genderData: data.gender_data,
        })
      }
    })
  }

  setSelectedMachine(str, id) {
    this.setState({
      selectedMachine: str,
      selectedMachineId: id,
      ageDataExists: false,
      ageDataLoading: true,
      genderDataExists: false,
      genderDataLoading: true,
    })

    this.fetchAgeData()
    this.fetchGenderData()
  }

  render() {
    let { campaigns, company, showdrop_logo } = this.props;
    let {
      ageDataExists,
      ageDataLoading,
      ageData,
      genderDataExists,
      genderDataLoading,
      genderData,
      selectedMachine,
      totalClickThroughs,
      totalRebateRedemptions,
      totalRebateSignUps,
      totalImpressions,
      totalDwells,
      totalQrScans,
      totalSamplesClaimed,
    } = this.state;

    let displayLogo = () => {
      if (company.logo) {
        return (
          <img className="w300 h-auto pdtb-20" src={company.logo}></img>
        )
      } else {
        return (
          <h1 className="pdtb-20">{company.name}</h1>
        )
      }
    }
    let showRebateSignUpHeader = () => {
      if (totalRebateSignUps > 0) {
        return (
          <>
            <th className="bg-extra-gray">Rebate Sign Ups</th>
            {
              totalRebateRedemptions > 0 ? (
                <th className="bg-extra-gray">Rebate Redemptions</th>
              ) : (
                null
              )
            }
          </>
        )
      } else {
        return null;
      }
    }
    let showClickThroughHeader = () => {
      if (totalClickThroughs) {
        return (
          <th className="bg-extra-gray">Customer Clicks</th>
        )
      } else {
        return null;
      }
    }
    let showRebateCountForAll = () => {
      if (totalRebateSignUps > 0) {
        return (
          <>
            <td>{numberWithCommas(totalRebateSignUps)}</td>
            {
              totalRebateRedemptions > 0 ? (
                <td>{numberWithCommas(totalRebateRedemptions)}</td>
              ) : (
                null
              )
            }
          </>
        )
      } else {
        return null;
      }
    }
    let showClicksForAll = () => {
      if (totalClickThroughs) {
        return (
          <td>{numberWithCommas(totalClickThroughs)}</td>
        )
      } else {
        return null
      }
    }
    let showRebateLegend = () => {
      if (totalRebateSignUps > 0) {
        return (
          <tr>
            <td className="bold bg-extra-gray">Rebate Sign Ups</td>
            <td># of rebate sign ups</td>
          </tr>
        )
      } else {
        return null;
      }
    }
    let demographicBarGraph = (graph_data) => {
      if (JSON.stringify(graph_data) === '{}') {
        return (
          <></>
        )
      } else {
        const data = {
          labels: graph_data.labels,
          datasets: graph_data.datasets,
        };
        const options = {
          barPercentage: 1,
          categoryPercentage: 1,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
              max: 100
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              position: "bottom",
            },
            tooltip: {
              mode: 'index',
              callbacks: {
                label: function(context) {
                  var label = context.dataset.label || '';
                  if (context.parsed.x !== null) {
                    label += ' ' +context.parsed.x + '%';
                  }
                  return label;
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1,
        };

        return (
          <Bar data={data} options={options} height={"160px"} />
        );
      }
    };
    let machineButtonFilter = (str, id) => {
      if (ageDataExists && genderDataExists) {
        return (
          <div className="hoverable" onClick={() => this.setSelectedMachine(str, id)} style={{ flex:"1",textAlign:"center",margin:"5px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",minWidth:"200px",maxWidth:"200px",backgroundColor:`${str == selectedMachine ? "#20cb9a" : "white"}`,color:`${str == selectedMachine ? "white" : "black"}` }}>
            {str}
          </div>
        )
      } else {
        if (str == selectedMachine) {
          return (
            <div onClick={null} style={{ flex:"1",textAlign:"center",margin:"5px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",minWidth:"200px",maxWidth:"200px",backgroundColor:"#20cb9a",color:"white" }}>
              {
                str == selectedMachine ? (
                  <>
                    <i className="fas fa-circle-notch fa-spin"></i>
                    <span style={{ paddingLeft:"4px" }}>{str}</span>
                  </>
                ) : (
                  <span>{str}</span>
                )
              }
            </div>
          )
        } else {
          return (
            <div onClick={null} style={{ flex:"1",textAlign:"center",margin:"5px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",minWidth:"200px",maxWidth:"200px",backgroundColor:"#a8a8a8",color:"white" }}>
              {str}
            </div>
          )
        }
      }
    }

    return (
      <div className="container-fluid bg-white fs-14 admin full-height">
        <div className="row bg-showdrop">
          <img className="h50 w-auto m-auto p10" src={showdrop_logo} />
        </div>

        <div className="row bg-white">
          <div className="col">
            {displayLogo()}
          </div>
        </div>

        <div className="row bg-extra-gray">
          <div className="col">
            <div style={{ display:"flex",width:"95%",margin:"auto",flexWrap:"wrap" }}>
              {machineButtonFilter("All", 0)}

              {company.machines.map(machine => {
                return (
                  machineButtonFilter(machine.name, machine.id)
                );
              })}
            </div>
          </div>
        </div>

        <div className="row bg-extra-gray">
          <div className="col">
            <h2 className="bold" style={{ marginTop:"10px" }}>{company.logo ? `Overview @ ${company.name}` : "Overview of Campaigns"}</h2>
          </div>
        </div>

        <div className="row bg-extra-gray">
          <div className="col">
            <div style={{ flex:"1",padding:"10px" }}>
              <div style={{ padding:"0 0 10px",fontSize:"16px",fontWeight:"bold" }}>Total Demographic Breakdown</div>

              <div style={{ height:"160px" }}>
                {
                  genderDataExists ? (
                    demographicBarGraph(genderData)
                  ) : (
                    genderDataLoading ? (
                      <div style={{ height:"140px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                        <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>
                          <i className="fas fa-circle-notch fa-spin"></i>
                          <span style={{ paddingLeft:"4px" }}>Loading Gender Data...</span>
                        </div>
                      </div>
                    ) : (
                      <div style={{ height:"140px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                        <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>Gender Data Unavailable</div>
                      </div>
                    )
                  )
                }
              </div>

              <div style={{ height:"160px" }}>
                {
                  ageDataExists ? (
                    demographicBarGraph(ageData)
                  ) : (
                    ageDataLoading ? (
                      <div style={{ height:"160px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                        <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>
                          <i className="fas fa-circle-notch fa-spin"></i>
                          <span style={{ paddingLeft:"4px" }}>Loading Age Data...</span>
                        </div>
                      </div>
                    ) : (
                      <div style={{ height:"160px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                        <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>Age Data Unavailable</div>
                      </div>
                    )
                  )
                }
              </div>
            </div>
          </div>
        </div>

        <div className="row ptb20-lr10 bg-extra-gray">
          <div className="col col-lg-12">
            <div className="table-responsive w100p m-auto bg-white">
              <table className="table table-sm center-text table-black-border mb-0">
                <thead>
                  <tr>
                    <th className="bg-extra-gray"></th>
                    <th className="bg-extra-gray">Launch Date</th>
                    <th className="bg-extra-gray">Machine</th>
                    <th className="bg-extra-gray">Brand</th>
                    <th className="bg-extra-gray">Product</th>
                    <th></th>
                    <th className="bg-light-purple">Impressions</th>
                    <th className="bg-purple">Dwells</th>
                    <th className="bg-showdrop-yellow">QR Scans</th>
                    <th className="bg-showdrop">Samples Claimed</th>
                    {
                      totalClickThroughs > 0 || totalRebateSignUps > 0 ? (
                        <th></th>
                      ) : (
                        null
                      )
                    }
                    {showClickThroughHeader()}
                    {showRebateSignUpHeader()}
                  </tr>
                </thead>
                <tbody>
                  {campaigns.map(campaign => {
                    return (
                      <CampaignRow
                        campaign={campaign}
                        selectedMachine={selectedMachine}
                        collectClickThroughs={this.collectClickThroughs}
                        totalClickThroughs={totalClickThroughs}
                        collectRebateRedemptions={this.collectRebateRedemptions}
                        totalRebateRedemptions={totalRebateRedemptions}
                        collectRebateSignUps={this.collectRebateSignUps}
                        totalRebateSignUps={totalRebateSignUps}

                        collectImpressions={this.collectImpressions}
                        totalImpressions={totalImpressions}
                        collectDwells={this.collectDwells}
                        totalDwells={totalDwells}
                        collectQrScans={this.collectQrScans}
                        totalQrScans={totalQrScans}
                        collectSamplesClaimed={this.collectSamplesClaimed}
                        totalSamplesClaimed={totalSamplesClaimed}
                      />
                    );
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{numberWithCommas(totalImpressions)}</td>
                    <td>{numberWithCommas(totalDwells)}</td>
                    <td>{numberWithCommas(totalQrScans)}</td>
                    <td>{numberWithCommas(totalSamplesClaimed)}</td>
                    {
                      totalClickThroughs > 0 || totalRebateSignUps > 0 ? (
                        <td></td>
                      ) : (
                        null
                      )
                    }
                    {showClicksForAll()}
                    {showRebateCountForAll()}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row ptb20-lr10 bg-extra-gray">
          <div className="col col-lg-6">
            <div className="table-responsive w100p m-auto bg-white">
              <table className="table table-sm table-black-border mb-0">
                <thead className="center-text">
                  <tr>
                    <th className="bg-extra-gray" colSpan={2}>Legend</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="bold bg-light-purple">Impressions</td>
                    <td># of times people walk by the Showdrop kiosk</td>
                  </tr>

                  <tr>
                    <td className="bold bg-purple">Dwells</td>
                    <td># of times people spend time directly in front of kiosk screen</td>
                  </tr>

                  <tr>
                    <td className="bold bg-showdrop-yellow">QR Scans</td>
                    <td># of customers who began sample retrieval process</td>
                  </tr>

                  <tr>
                    <td className="bold bg-showdrop">Samples Claimed</td>
                    <td># of customers who gave contact info to receive PIN to retrieve sample</td>
                  </tr>

                  <tr>
                    <td className="bold bg-extra-gray">Customer Clicks</td>
                    <td># of times customers clicked on the links sent to them</td>
                  </tr>

                  {showRebateLegend()}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row ptb20-lr10 bg-extra-gray"></div>

        <div className="row ptb20-lr10 bg-white"></div>
      </div>
    );
  }
}

function SumArray(data) {
  return data.reduce((a, v) => (a = a + v), 0);
}

function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
