import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Errors from "../shared/Errors";
import Row from "./configuration/Row";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      empty: false,
      errors: '',
      inStockVideo: this.props.machine.planogram.in_stock_video,
      inStockVideoUploaded: false,
      loading: false,
      maxedOut: false,
      message: '',
      onDesktop: true,
      outOfStockVideo: this.props.machine.planogram.out_of_stock_video,
      outOfStockVideoUploaded: false,
    };

    this.changeInStockVideo = this.changeInStockVideo.bind(this)
    this.changeOutOfStockVideo = this.changeOutOfStockVideo.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.endCampaigns = this.endCampaigns.bind(this)
    this.enterMaintenance = this.enterMaintenance.bind(this)
    this.getTemperature = this.getTemperature.bind(this)
    this.resetValues = this.resetValues.bind(this)
    this.setEmptyOnMachine = this.setEmptyOnMachine.bind(this)
    this.setMaxOnMachine = this.setMaxOnMachine.bind(this)
    this.setOutOfStock = this.setOutOfStock.bind(this)
    this.setTemperatureMode0 = this.setTemperatureMode0.bind(this)
    this.setTemperatureMode1 = this.setTemperatureMode1.bind(this)
    this.setTemperatureMode2 = this.setTemperatureMode2.bind(this)
    this.setTemperatureLock = this.setTemperatureLock.bind(this)
    this.startConfiguration = this.startConfiguration.bind(this)
    this.syncAdvertisements = this.syncAdvertisements.bind(this)
    this.syncCodes = this.syncCodes.bind(this)
    this.syncProducts = this.syncProducts.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeInStockVideo(event) {
    this.setState({
      inStockVideo: event.target.files[0],
      inStockVideoUploaded: true
    })
  }

  changeOutOfStockVideo(event) {
    this.setState({
      outOfStockVideo: event.target.files[0],
      outOfStockVideoUploaded: true
    })
  }

  editRecord() {
    this.setState({
      loading: true
    })
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.machine.planogram.machine_planogram_id
    })
    formData.append('machine_planogram', data)

    if (this.state.inStockVideoUploaded) {
      formData.append('in_stock_video', this.state.inStockVideo)
    }
    if (this.state.outOfStockVideoUploaded) {
      formData.append('out_of_stock_video', this.state.outOfStockVideo)
    }

    fetch(this.props.url_update_video, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
          loading: false
        })
      } else {
        window.location.reload();
      }
    })
  }

  endCampaigns(ids, url) {
    if( confirm('Are you sure you want to end ALL the campaigns on this machine?') ) {
      let data = JSON.stringify({
        ids: ids,
      })

      fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data
      }).then(response => {
        return response.json();
      }).then(data => {
        window.location.reload()
      })
    }
  }

  enterMaintenance() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_enter_maintenance, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  getTemperature() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_get_temp, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  resetValues() {
    this.setState({
      empty: false,
      maxedOut: false,
    })
  }

  setEmptyOnMachine() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      machine_id: this.props.machine.id,
    })
    formData.append('planogram_product', data)

    fetch(this.props.machine.set_empty, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.reload();
      }
    })
  }

  setMaxOnMachine() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      machine_id: this.props.machine.id,
    })
    formData.append('planogram_product', data)

    fetch(this.props.machine.set_max, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.reload();
      }
    })
  }

  setOutOfStock() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_set_out_of_stock, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  setTemperatureMode0() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_set_temp_mode0, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  setTemperatureMode1() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_set_temp_mode1, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  setTemperatureMode2() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_set_temp_mode2, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  setTemperatureLock() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_set_temp_lock, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  startConfiguration() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_start_configuration, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  syncAdvertisements() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_advertisements, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })
    })
  }

  syncCodes() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_codes, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })
    })
  }

  syncProducts() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_products, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })
    })
  }

  render () {
    let { machine, products, showdrop_logo, url_options, url_end_all_campaigns } = this.props;
    let { errors, loading, message, onDesktop } = this.state;
    let pageName = 'configure_machine';

    const showMessage = () => {
      if (message) {
        return (
          <p>{message}</p>
        )
      } else {
        return null;
      }
    }
    const availabilityCheck = (rows, coilType) => {
      let coils = rows.filter(row => row.columns.every(c => c.max_quantity == coilType)).map(coil => coil.columns ).flat()
      let anyCoils = coils.length > 0
      let emptyCoils = coils.filter(coil => coil.product.quantity <= 0).length
      let emptyCoilSlots = emptyCoils * coilType
      let partialCoilSlots = coils.filter(coil => coil.product.quantity != 0).map(coil => coilType - coil.product.quantity).reduce((partialSum, a) => partialSum + a, 0);

      return (
        <div style={{ flex:"1",backgroundColor:`${anyCoils ? "#333333" : "#c7c7c7"}`,padding:"5px",marginRight:`${coilType == 28 ? '0' : "5px"}`,color:"white",borderRadius:"4px 4px 0 0" }}>
          <div style={{ textAlign:"center",fontSize:"20px",fontWeight:"bold" }}>{coilType} Count</div>
          <table style={{ margin:"auto" }}>
            <tbody>
              <tr>
                <td style={{ textAlign:"right" }}>Total Empty Coils:</td>
                <td style={{ textAlign:"left",fontWeight:"bold",paddingLeft:"2px" }}>
                  {
                    anyCoils ? (
                      <>{emptyCoils} ({emptyCoilSlots})</>
                    ) : (
                      "N/A"
                    )
                  }
                </td>
              </tr>

              <tr>
                <td style={{ textAlign:"right" }}>Partial Empty Slots:</td>
                <td style={{ textAlign:"left",fontWeight:"bold",paddingLeft:"2px" }}>
                  {
                    anyCoils ? (
                      <>{partialCoilSlots}</>
                    ) : (
                      "N/A"
                    )
                  }
                </td>
              </tr>

              <tr>
                <td style={{ textAlign:"right" }}>Total Empty Slots:</td>
                <td style={{ textAlign:"left",fontWeight:"bold",paddingLeft:"2px" }}>
                  {
                    anyCoils ? (
                      <>{emptyCoilSlots + partialCoilSlots}</>
                    ) : (
                      "N/A"
                    )
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={`${machine.planogram.name} // ${machine.name}`}
              url_show={null}
              url_new={null}
            />

            <div className="m10 center-text color-green italic">
              {showMessage()}
            </div>

            {
              machine.out_of_stock ? (
                <div className="m10 center-text color-red bold fs-18">
                  Machine is Currently Out of Stock!!
                </div>
              ) : (
                null
              )
            }

            <div className="p10">
              <dl className="row">
                <dt className="col-sm-3 text-right">
                  In Stock Video
                  <div className="fs-10 thin">(The video on the machine played when the machine is in-stock)</div>
                </dt>
                <dd className="col-sm-9">
                  <label>{machine.planogram.in_stock_video_name}</label>
                  <input className="form-control" type="file" id="formFile" onChange={this.changeInStockVideo} />
                </dd>

                <dt className="col-sm-3 text-right">
                  Out of Stock Video
                  <div className="fs-10 thin">(The video on the machine played when the machine is out-of-stock)</div>
                </dt>
                <dd className="col-sm-9">
                  <label>{machine.planogram.out_of_stock_video_name}</label>
                  <input className="form-control" type="file" id="formFile" onChange={this.changeOutOfStockVideo} />
                </dd>

                <dt className="col-sm-3 text-right"></dt>
                <dd className="col-sm-9">
                  {
                    loading ? (
                      <button type="button" className="btn btn-warning" onClick={null} disabled="disabled">
                        <i className="fas fa-circle-notch fa-spin"></i>
                      </button>
                    ) : (
                      <button type="button" className="btn btn-warning" onClick={this.editRecord}>Add Videos</button>
                    )
                  }
                </dd>
              </dl>
            </div>

            {
              machine.planogram.inventory_editable ? (
                <>
                  <div className="d-flex m10">
                    <button className="f1 m10 p10 border-none color-white bold bg-secondary" style={{ borderRadius:"4px" }}  onClick={this.setOutOfStock} disabled={loading ? 'disabled' : ''}>
                      {
                        machine.out_of_stock ? (
                          <>
                            <i className="fas fa-unlock pdr-5"></i>
                            Set Machine In Stock
                          </>
                        ) : (
                          <>
                            <i className="fas fa-lock pdr-5"></i>
                            Set Machine Out of Stock
                          </>
                        )
                      }
                    </button>

                    <button className="f1 m10 p10 border-none color-white bold bg-red" style={{ borderRadius:"4px" }}  onClick={this.enterMaintenance} disabled={loading ? 'disabled' : ''}>
                      <i className="fas fa-wrench pdr-5"></i>
                      Enter Maintenance
                    </button>

                    <button className="f1 m10 p10 border-none color-white bold bg-primary" style={{ borderRadius:"4px" }} onClick={this.startConfiguration} disabled={loading ? 'disabled' : ''}>
                      <i className="fas fa-toolbox pdr-5"></i>
                      Start Configuration
                    </button>

                    <button className="f1 m10 p10 border-none color-white bold bg-primary" style={{ borderRadius:"4px" }} onClick={this.syncCodes} disabled={loading ? 'disabled' : ''}>
                      <i className="fas fa-code pdr-5"></i>
                      Sync Codes
                    </button>

                    <button className="f1 m10 p10 border-none color-white bold bg-primary" style={{ borderRadius:"4px" }} onClick={this.syncProducts} disabled={loading ? 'disabled' : ''}>
                      <i className="fas fa-shopping-cart pdr-5"></i>
                      Sync Products
                    </button>

                    <button className="f1 m10 p10 border-none color-white bold bg-primary" style={{ borderRadius:"4px" }} onClick={this.syncAdvertisements} disabled={loading ? 'disabled' : ''}>
                      <i className="fas fa-bullhorn pdr-5"></i>
                      Sync Advertisements
                    </button>
                  </div>

                  <div className="d-flex m10">
                    <button className="f1 m10 p10 border-none color-white bold bg-success" style={{ borderRadius:"4px" }}  onClick={this.getTemperature} disabled={loading ? 'disabled' : ''}>
                      <i className="fas fa-thermometer-full pdr-5"></i>
                      Get Current Temperature
                    </button>

                    <button className="f1 m10 p10 border-none color-white bold bg-info" style={{ borderRadius:"4px" }}  onClick={this.setTemperatureMode0} disabled={loading ? 'disabled' : ''}>
                      <i className="fas fa-power-off pdr-5"></i>
                      Set Ambient Temperature
                    </button>

                    <button className="f1 m10 p10 border-none color-white bold bg-info" style={{ borderRadius:"4px" }}  onClick={this.setTemperatureMode1} disabled={loading ? 'disabled' : ''}>
                      <i className="fas fa-wind pdr-5"></i>
                      Set Weak Cooling Temperature
                    </button>

                    <button className="f1 m10 p10 border-none color-white bold bg-info" style={{ borderRadius:"4px" }}  onClick={this.setTemperatureMode2} disabled={loading ? 'disabled' : ''}>
                      <i className="fas fa-snowflake pdr-5"></i>
                      Set Strong Cooling Temperature
                    </button>

                    <button className="f1 m10 p10 border-none color-white bold bg-secondary" style={{ borderRadius:"4px" }}  onClick={this.setTemperatureLock} disabled={loading ? 'disabled' : ''}>
                      {
                        machine.temperature_lock ? (
                          <>
                            <i className="fas fa-unlock pdr-5"></i>
                            Unlock Temperature Mode
                          </>
                        ) : (
                          <>
                            <i className="fas fa-lock pdr-5"></i>
                            Lock Temperature Mode
                          </>
                        )
                      }
                    </button>
                  </div>

                  <div style={{ paddingBottom:"10px" }}>
                    <div className="pos-r" style={{ paddingLeft:"20px" }}>
                      Current Temperature:
                      <strong> {machine.temperature}°</strong>
                    </div>

                    <div className="pos-r" style={{ paddingLeft:"20px" }}>
                      Current Temperature Mode:
                      <strong> {machine.temperature_mode} {machine.temperature_lock ? "(Locked)" : ""} </strong>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="m10 center-text">
                    <h2 style={{ color:"red" }}>!!! ACTIVE !!!</h2>
                  </div>

                  <div className="d-flex m10">
                    <button className="f1 m10 p10 color-white bg-red border-none bold" style={{ borderRadius:"4px" }} onClick={machine.planogram.inventory_editable ? this.setEmptyOnMachine : null}>
                      Machine Empty
                    </button>

                    <button className="f1 m10 p10 color-white bg-green border-none bold" style={{ borderRadius:"4px" }} onClick={machine.planogram.inventory_editable ? this.setMaxOnMachine : null}>
                      Machine Max
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="m10 center-text" style={{ marginBottom:'0' }}>
                    <h2 style={{ color:"#00000050",marginBottom:'0' }}>INACTIVE</h2>
                  </div>

                  <div className="m10 center-text" style={{ marginTop:'0' }}>
                    <div style={{ display:"flex",justifyContent:"center" }}>
                      <p style={{ fontSize:"16px" }}>Launches <strong>{machine.planogram.launch_date}</strong></p>

                      <a href={machine.planogram.set_date_url} style={{ marginLeft:"5px" }}>Change Date?</a>
                    </div>
                  </div>

                  <hr />
                </>
              )
            }

            <div style={{ marginBottom:"20px" }}>
              <ul className="no-list" style={{ padding:"0" }}>
                {
                  products.map(product => {
                    return (
                      <li>{product.name}</li>
                    )
                  })
                }
              </ul>

              <div style={{ display:"flex" }}>
                <div style={{ flex:"1",maxWidth:"180px" }}>
                  <button type="button" className="btn btn-outline-success" onClick={(e) => {e.stopPropagation(); window.location.assign(url_options)}}>
                    Add More Products
                  </button>
                </div>

                <div style={{ flex:"1",maxWidth:"180px" }}>
                  <button type="button" className="btn btn-outline-danger" onClick={(e) => {e.stopPropagation(); this.endCampaigns(products.map(product => product.campaign_id), url_end_all_campaigns)}}>
                    End All Campaigns
                  </button>
                </div>
              </div>
            </div>

            <div style={{ display:"flex" }}>
              {availabilityCheck(machine.planogram.rows, 5)}
              {availabilityCheck(machine.planogram.rows, 7)}
              {availabilityCheck(machine.planogram.rows, 9)}
              {availabilityCheck(machine.planogram.rows, 12)}
              {availabilityCheck(machine.planogram.rows, 28)}
            </div>

            <dl className="row" style={{ width:"100%" }}>
              <RenderRows
                empty={this.state.empty}
                maxedOut={this.state.maxedOut}
                products={products}
                resetValues={this.resetValues}
                rows={machine.planogram.rows}
                machine={machine}
              />
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}

function RenderRows(props) {
  let { empty, machine, maxedOut, products, resetValues, rows } = props;

  if (rows.length > 0) {
    return (
      <>
        {rows.map((row, i) => {
          return (
            <Row
              empty={empty}
              index={i}
              machine={machine}
              maxedOut={maxedOut}
              products={products}
              resetValues={resetValues}
              row={row}
            />
          )
        })}
      </>
    )
  } else {
    return null;
  }
}
