import React from "react"
import Nav from "../../shared/Nav";
import IndexHeader from "../../shared/headers/IndexHeader";
import AdminHeader from "../../shared/headers/AdminHeader";
import CampaignRow from "./CampaignRow";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandDirection: "",
      sortedCampaigns: [],
      unsortedCampaigns: [],
      idDirection: "",
      loading: false,
      machineNameDirection: "",
      onDesktop: true,
      productNameDirection: "",
    }

    this.sortByBrand = this.sortByBrand.bind(this)
    this.sortById = this.sortById.bind(this)
    this.sortByMachineName = this.sortByMachineName.bind(this)
    this.sortByProductName = this.sortByProductName.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop,
      loading: true
    })

    let that = this;

    fetch(this.props.fetch_url, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
          loading: false,
        })
      } else {
        that.setState({
          sortedCampaigns: data.campaigns,
          unsortedCampaigns: data.campaigns,
          loading: false,
        })
      }
    })
  }

  sortByBrand() {
    let camps = this.state.unsortedCampaigns
    let direction = '';

    if (this.state.brandDirection == "DESC") {
      camps = camps.sort((a, b) => a.brand.name > b.brand.name ? -1 : 1);
      direction = "ASC"
    } else {
      camps = camps.sort((a, b) => a.brand.name > b.brand.name ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      idDirection: "",
      brandDirection: direction,
      machineNameDirection: "",
      productNameDirection: "",
      sortedCampaigns: camps,
    })
  }

  sortById() {
    let camps = this.state.unsortedCampaigns
    let direction = '';

    if (this.state.idDirection == "DESC") {
      camps = camps.sort((a, b) => a.id > b.id ? -1 : 1);
      direction = "ASC"
    } else {
      camps = camps.sort((a, b) => a.id > b.id ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      idDirection: direction,
      brandDirection: "",
      machineNameDirection: "",
      productNameDirection: "",
      sortedCampaigns: camps,
    })
  }

  sortByMachineName() {
    let camps = this.state.unsortedCampaigns
    let direction = '';

    if (this.state.machineNameDirection == "DESC") {
      camps = camps.sort((a, b) => a.machine.name > b.machine.name ? -1 : 1);
      direction = "ASC"
    } else {
      camps = camps.sort((a, b) => a.machine.name > b.machine.name ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      idDirection: "",
      brandDirection: "",
      machineNameDirection: direction,
      productNameDirection: "",
      sortedCampaigns: camps,
    })
  }

  sortByProductName() {
    let camps = this.state.unsortedCampaigns
    let direction = '';

    if (this.state.productNameDirection == "DESC") {
      camps = camps.sort((a, b) => a.product.name > b.product.name ? -1 : 1);
      direction = "ASC"
    } else {
      camps = camps.sort((a, b) => a.product.name > b.product.name ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      idDirection: "",
      brandDirection: "",
      machineNameDirection: "",
      productNameDirection: direction,
      sortedCampaigns: camps,
    })
  }

  render () {
    let {
      showdrop_logo,
    } = this.props;
    let {
      sortedCampaigns,
      loading,
      onDesktop,
      idDirection,
      machineNameDirection,
      productNameDirection,
      brandDirection,
    } = this.state;
    let pageName = 'campaign_stats';

    const showCampaigns = () => {
      if (loading) {
        return (
          <div style={{ textAlign:"center" }}>
            <i className="fas fa-circle-notch fa-spin fs-80"></i>
          </div>
        )
      } else {
        return (
          <>
            <table className="table table-long table-sm table-hover table-bordered">
              <thead>
                <tr>
                  <th className="sticky bg-extra-gray center-text">
                    Share w/
                    <div>Brand</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text sortable" onClick={this.sortById}>
                    ID
                    <RenderArrow direction={idDirection} />
                  </th>
                  <th className="sticky bg-extra-gray center-text sortable" onClick={this.sortByBrand}>
                    Brand
                    <RenderArrow direction={brandDirection} />
                  </th>
                  <th className="sticky bg-extra-gray center-text sortable" onClick={this.sortByProductName}>
                    Product Name
                    <RenderArrow direction={productNameDirection} />
                  </th>
                  <th className="sticky bg-extra-gray center-text sortable" onClick={this.sortByMachineName}>
                    Machine Name
                    <RenderArrow direction={machineNameDirection} />
                  </th>
                  <th className="sticky bg-extra-gray center-text">Impressions</th>
                  <th className="sticky bg-extra-gray center-text">Dwells</th>
                  <th className="sticky bg-extra-gray">
                    Avg
                    <div>Dwell</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    QR
                    <div>Scans</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    PIN
                    <div>Requests</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    PIN
                    <div>Shown</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    PIN
                    <div>Shown (u)</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    PIN
                    <div>Inputs</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    Valid PIN
                    <div>Inputs (u)</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    Eligible
                    <div>Choices (u)</div>
                  </th>
                  <th className="sticky bg-green color-white center-text bold">
                    Pin Status
                    <div>(Claimed)</div>
                  </th>
                  <th className="sticky bg-green color-white center-text bold">Pref %</th>
                  <th className="sticky bg-extra-gray center-text">
                    Pin Status
                    <div>(Dis Error)</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">
                    Shop Click
                    <div>Text</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">
                    Eng Click
                    <div>Text</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">
                    Rebate
                    <div>Sign Ups</div>
                  </th>
                  <th className="sticky bg-extra-gray">Orders</th>
                  <th className="sticky bg-extra-gray center-text">
                    Brand
                    <div>Orders</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedCampaigns.map(campaign => {
                  return (
                    <CampaignRow campaign={campaign} />
                  )
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="right-text">{numberWithCommas(SumArray(sortedCampaigns.map(campaign => campaign.qr_scans)))}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </>
        )
      }
    }
    const toggleIcon = (campaign) => {
      if (campaign.brand_hidden) {
        return (
          <i className="fas fa-toggle-off clickable" onClick={this.toggleBrandHidden}></i>
        )
      } else {
        return (
          <i className="fas fa-toggle-on clickable" onClick={this.toggleBrandHidden}></i>
        )
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white">
            <IndexHeader
              name={'Campaign Stats'}
              url={null}
            />

            <div className="row pb-10 pos-r">
              <div className="m-auto w100p o-x-scroll h1000">
                {showCampaigns()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function SumArray(data) {
  return data.reduce((a,v) =>  a = a + v, 0 )
}

function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function RenderArrow(props) {
  let { direction } = props;

  if (direction) {
    if (direction == "DESC") {
      return (
        <i className="fas fa-caret-down"></i>
      )
    } else {
      return (
        <i className="fas fa-caret-up"></i>
      )
    }
  } else {
    return null;
  }
}
